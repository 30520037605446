.portfolio__mobile__title {
  margin-top: 3.5rem;
  font-weight: 100;
  display: block;
  font-size: 6rem;
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #0cc3db, 0 0 30px #0cc3db,
    0 0 40px rgb(12, 195, 219), 0 0 55px #0cc3db, 0 0 75px #0cc3db;
  text-align: center;
  text-transform: uppercase;
}

.main-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
}

.main-project {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-project__title {
  color: #fff;
  font-size: 32px;
  text-transform: uppercase;
}

.main-project__gif {
  border-radius: 4.5rem;
  overflow: hidden;

  -webkit-box-shadow: inset 9px 6px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 9px 6px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: inset 9px 6px 20px 0px rgba(0, 0, 0, 0.75);
}

.main-project__gif img {
  height: 100%;
  width: 100%;
  position: relative;
}

.main-project__icons i {
  color: transparent;
  margin-bottom: 0.5rem;
  display: inline-block;
  background-image: linear-gradient(to left, #0cc3db, #1d976c);
  background-clip: text;
  -webkit-background-clip: text;
  margin: 0 5px;
}

.mobile-project {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-project__frame {
  height: 350px;
  width: 175px;
  position: relative;
}

.mobile-project__gif {
  position: absolute;
  height: 91%;
  width: 88%;
  top: 2%;
  left: 7%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
