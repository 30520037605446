.numbers-section {
  padding: 15rem 0;
}

.numbers-section .square-item {
  margin-top: 3rem;
  position: relative;
  max-width: 90%;
  margin: 2.5rem auto;
}

.square-item .square-inner-container {
  position: relative;
  padding: 2rem;
  text-align: center;
  border: 2px solid var(--primary-color);
  border-top: none;
}

.square-item .square-inner-container::before,
.square-item .square-inner-container::after {
  content: '';
  position: absolute;
  top: 0;
  width: 30%;
  border-bottom: 2px solid var(--primary-color);
}

.square-item .square-inner-container::before {
  right: 0;
}

.square-item .square-inner-container::after {
  left: 0;
}

.square-item .square-icon {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(-50%);
}

.square-item .square-icon i {
  background-image: linear-gradient(to right, var(--secondary-color), var(--primary-color));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.square-item .square-content h2 {
  font-size: 5rem;
  color: #fff;
  font-weight: 200;
  text-shadow: var(--text-neon);
}

.square-item .square-content h3 {
  text-transform: uppercase;
  background-image: linear-gradient(to right, var(--secondary-color), var(--primary-color));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 2rem;
  letter-spacing: 1px;
}
