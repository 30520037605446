@import "~react-image-gallery/styles/scss/image-gallery.scss";

 .certificate-section {
  width: 100%;

  .image-gallery-slides {
    border-radius: 3rem;
  }
}

.certificate-heading {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 2, 5rem;
  text-align: center;
  text-shadow: var(--text-neon);
  margin-bottom: 5rem;
}

.certificate-section .certificate-item {
  margin: 5rem auto 0 auto;
  height: auto;
  width: 60%;
  -webkit-box-shadow: inset -1px 16px 20px 1px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: inset -1px 16px 20px 1px rgba(0, 0, 0, 0.53);
  box-shadow: inset -1px 16px 20px 1px rgba(0, 0, 0, 0.53);
  border-radius: 3rem;
}

.certificate-item .certificate-image {
  border-radius: 3rem;
  filter: grayscale(70%);
  z-index: -2;
} 